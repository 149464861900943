import { getInstaller } from '@/utils';
import clickoutside from './src/clickoutside';
import directives from './src/directives';
import longpress from './src/longPress';

export { clickoutside, directives,longpress };

// 全局注册
const globalDirectives = {};

export default getInstaller('directive', globalDirectives);

<template>
  <div>
    <div class="tc" v-if="recording">
      {{ `正在录音...${recordTime}` }}
    </div>
    <div class="tc" v-if="isPlaying">
      {{ `播放录音...` }}
    </div>
    <div class="read-tab zm-flex is-align-flex-end">
      <div class="item flex-1" @click="onRepeat">
        <zm-icon icon-class="repeat-fill" class="fs32 mb10"></zm-icon>
        <div class="name">重听单词</div>
      </div>
      <div
        class="item flex-1 record-btn"
        v-longpress:300="handleRecord"
        @touchend="cancelRecord"
        @mouseup="cancelRecord"
      >
        <!-- @click="handleRecord" -->
        <zm-icon icon-class="record-fill" class="record-icon mb10"></zm-icon>
        <div class="name">{{ btnText }}</div>
      </div>
      <div class="item flex-1" @click="onPlay">
        <zm-icon :icon-class="isPlaying ? 'circlePause' : 'play-fill'" class="fs32 mb10"></zm-icon>
        <div class="name">{{ isPlaying ? '暂停播放' : '自动播放' }}</div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import { longpress } from '@/directives';
import { mapGetters } from 'vuex';
import Recorderx, { ENCODE_TYPE } from 'recorderx';
const recordTime = '12';
export default {
  name: 'ReadBtn',
  components: {},
  directives: {
    longpress
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    audioArr: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      myAudio: new Audio(),
      btnText: '开始录音',
      rc: 0,
      voiceTemp: null,
      wavTemp: null,
      wavFileUrl: '',
      recording: false,
      isPlaying: false,
      recordTime: 12, // 录音时长
      secTemp: 0,
      sec: 0
    };
  },
  computed: {
    ...mapGetters(['unitIndex', 'unitListLength']),
    isAndroi() {
      return window.plus;
    }
  },
  watch: {},
  created() {},
  methods: {
    onRecord() {},
    handleRecord() {
      let vm = this;
      // if (this.isPlaying) return;
      if (!this.recording) {
        // if (navigator.getUserMedia) {
        this.$nextTick(function() {
          // eslint-disable-next-line no-undef
          vm.rc = window.plus && plus.audio ? plus.audio.getRecorder() : new Recorderx();
          vm.recording = true;
          vm.setSec(+recordTime);
          // eslint-disable-next-line no-undef
          if (this.isAndroi) {
            this.recordAndroi(vm.rc);
          } else {
            this.recordBrowser(vm.rc);
          }
          vm.btnText = '正在录音';
        });
      } else {
        let numArr = this.sec.split('"');
        if (+numArr[0] > 0) {
          this.doneRecord();
        }
      }
    },
    recordAndroi(rc) {
      let vm = this;
      rc.record(
        { filename: '_doc/audio/temp-word-record.amr' },
        function(filePath) {
          // eslint-disable-next-line no-undef
          plus.io.resolveLocalFileSystemURL(
            filePath,
            function(entry) {
              vm.wavFileUrl = filePath;
              vm.onPlay();
            },
            function(e) {
              vm.$toast.fail('读取录音失败：' + e.message);
            }
          );
          console.log('filePath', filePath);
        },
        function(e) {
          alert('Audio record failed: ' + e.message);
        }
      );
    },
    stopRecord() {
      this.rc && this.rc.stop();
    },
    recordBrowser(rc) {
      let vm = this;
      rc.start()
        .then(stream => {
          console.log(stream);
          vm.voiceTemp = stream; // 媒体流缓存
        })
        .catch(error => {
          console.log('' + error);
          let errmsg = '' + error;
          if (errmsg.indexOf('NotAllowedError') > -1) {
            vm.$toast.fail('错误：请开启录音权限');
          } else if (errmsg.indexOf('NotSupportedError') > -1) {
            vm.$toast.fail('错误：当前浏览器不支持硬件设备');
          } else {
            vm.$toast.fail('错误：请检查麦克风设备或更换浏览器后重试');
          }
          vm.rc = 0;
          vm.cancelRecord();
        });
    },
    doneRecord() {
      let vm = this;
      this.$nextTick(function() {
        vm.btnText = '开始录音';
        vm.recording = false;
        // eslint-disable-next-line no-undef
        if (!this.isAndroi) {
          const wavTemp = vm.rc.getRecord({
            encodeTo: ENCODE_TYPE.WAV,
            compressible: true
          });
          vm.wavFileUrl = URL.createObjectURL(wavTemp);
        }
      });
    },
    setSec(num) {
      this.sec = +recordTime - num + '"';
      num -= 1;
      let timer = null;
      this.recordTime = num;
      if (this.recording) {
        if (num > -1) {
          timer = setTimeout(() => {
            this.setSec(num);
          }, 1000);
        } else {
          clearTimeout(timer);
          timer = null;
          this.doneRecord();
        }
      } else {
        this.sec = this.secTemp;
        console.log('sec', this.sec);
      }
      this.secTemp = this.sec;
      console.log(this.sec, this.secTemp);
    },
    cutSec(num) {
      this.sec = num + '"';
      num -= 1;
      let timer = null;
      if (num > -1) {
        let timer = setTimeout(() => {
          this.cutSec(num);
        }, 1000);
      } else {
        this.sec = this.secTemp;
        this.isPlaying = false;
      }
    },
    onPlay() {
      let vm = this;
      if (!this.wavFileUrl) return this.$toast('请先录音');
      if (this.isAndroi) {
        if (!this.isPlaying) {
          let player = plus.audio.createPlayer(this.wavFileUrl),
            numArr = vm.sec.split('"');
          this.isPlaying = true;
          vm.cutSec(+numArr[0]);
          player.play(
            function() {
              // alert('play ended');
              console.log('play ended');
            },
            function(e) {
              console.log('message：' + e.message);
              // alert('message：' + e.message);
            }
          );
        }
      } else {
        if (!this.isPlaying) {
          let audio = new Audio(),
            numArr = this.sec.split('"'),
            vm = this;
          audio.autoplay = true;
          this.isPlaying = true;
          this.$nextTick(function() {
            vm.cutSec(+numArr[0]);
            audio.src = vm.wavFileUrl;
          });
        }
      }
    },
    cancelRecord() {
      console.log('cancel');
      if (this.isAndroi) {
          this.stopRecord();
          this.recording = false;
          this.btnText = '开始录音';
          this.voiceTemp = null;
          this.doneRecord();
      } else {
         if (this.rc) {
          this.rc.pause();
          this.recording = false;
          this.btnText = '开始录音';
          this.voiceTemp = null;
          this.doneRecord();
        }
      }
    },
    onRepeat() {
      this.$emit('repeat');
    }
  }
};
</script>
<style lang="scss" scoped>
.read-tab {
  //   background: darken($--color-primary, 5%);
  padding: 0.5rem 0.5rem;
  .circle-play {
    font-size: 1rem;
  }
  .item {
    text-align: center;
    font-size: 0.24rem;
    color: $--color-primary;
  }
  .audio-box {
    display: none;
  }
  .record-btn {
    .record-icon {
      font-size: 1rem;
    }
  }
}
</style>
